/* =============================================================
   CSS Variables: Root Styles
============================================================= */
:root {
  /* Color Variables */
  --background-color: #f9fafc;
  --main-bg-color: #ffffff;
  --text-color: #333;
  --sidebar-bg-color: #f9fafc;
  --top-bar-bg-color: #f9fafc;
  --right-sidebar-bg-color: #f9fafc;
  --border-color: #e0e0e0;
  --button-bg-color: #6a3109;
  --button-hover-bg-color: #0573e8;
  --chat-button-bg-color: #28a745;
  --chat-button-hover-bg-color: #218838;
  --hover-bg-color: #fcfbfb;
}

/* =============================================================
   General Layout Styles
============================================================= */

/* Application Wrapper */
.app {
  display: flex;
  height: 100vh;
  font-family: 'Inter', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  
}

/* Main Container */
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

/* Content Container */
.content-container {
  display: flex;
  flex-grow: 1;
  height: 100%;
  
}

/* Sidebars Wrapper */
.sidebars-wrapper {
  position: relative;
  width: 30%; /* Combined width of both sidebars */
  display: flex;
  justify-content: flex-end;
  height: 100%;
}

/* =============================================================
   Sidebar Styles
============================================================= */
.voice-controls-heading {
  color: var(--text-color); /* White text */
}
/* Sidebar */
.sidebar {
  background-color: var(--sidebar-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
}

/* Right Sidebar */
.right-sidebar {
  flex-grow: 0;
  width: 240px;
  background-color: var(--right-sidebar-bg-color);
  padding: 20px;
  overflow-y: auto;
  border-left: 1px solid var(--border-color);
  border-top: 1px solid var(--border-color);
}

/* Right Sidebar List Items */
.right-sidebar ul li {
  padding: 10px 0;
  padding-left: 10px;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  overflow-y: auto;
}

/* Right Sidebar Hover Effect */
.right-sidebar ul li:hover {
  background-color: #d1efff52;
}

/* =============================================================
   Top Bar Styles
============================================================= */

/* Top Bar */
.top-bar {
  background-color: var(--top-bar-bg-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  box-sizing: border-box;
  z-index: 1;
}

/* =============================================================
   Main Content Styles
============================================================= */

/* Main Content Area */
.main-content {
  flex-grow: 1;
  background-color: var(--main-bg-color);
  padding: 40px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 0px;
  border-top: 1px solid var(--border-color);
  overflow-y: auto;
}

/* Headers */
h1 {
  font-size: 36px;
  margin-bottom: 20px;
  color: var(--text-color);
}

/* Paragraphs */
p {
  color: var(--text-color);
  font-size: 18px;
  text-align: center;
  max-width: 500px;
  margin-bottom: 40px;
}

/* =============================================================
   Button and Action Styles
============================================================= */

/* Actions Container */
.actions {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
}

/* Action Button */
.action-button {
  background-color: var(--button-bg-color);
  color: white;
  border: none;
  padding: 15px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}

/* Action Button Hover Effect */
.action-button:hover {
  background-color: var(--button-hover-bg-color);
}

/* Chat Input Container */
.chat-input {
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: 700px;
}

/* Chat Input Field */
.chat-input input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
}

/* Chat Input Button */
.chat-input button {
  background-color: var(--chat-button-bg-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* Chat Input Button Hover Effect */
.chat-input button:hover {
  background-color: var(--chat-button-hover-bg-color);
}

/* =============================================================
   Mobile Message Styles
============================================================= */

/* Mobile Message */
.mobile-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  font-size: 1.5em;
  padding: 20px;
  background-color: var(--hover-bg-color);
}

/* =============================================================
   Dark Mode Styles
============================================================= */

/* Dark Mode Variables */
body.dark-mode {
  --background-color: #031525;
  --main-bg-color: #292929;
  --text-color: #ffffff;
  --sidebar-bg-color: #000000;
  --top-bar-bg-color: #031525;
  --right-sidebar-bg-color: #000000;
  --border-color: #ffffff;
  --button-bg-color: #444;
  --button-hover-bg-color: #fbfbfb;
  --chat-button-bg-color: #28a745;
  --chat-button-hover-bg-color: #218838;
  --hover-bg-color: #444;
}
body.dark-mode h1 {
  color: var(--text-color);
}

body.dark-mode p {
  color: var(--text-color);
}

body.dark-mode .filter-tabs button {
  background-color: #031525;
  color: var(--text-color);
}
body.dark-mode .filter-tabs button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--text-color);
}
body.dark-mode .chat-type-toggle-button {
  color: var(--text-color);
}
body.dark-mode .chat-type-toggle-button:hover {
  background-color: #031525;
}
body.dark-mode .chevron-icon {
  color: var(--text-color);
}
body.dark-mode .chat-type-dropdown-menu {
  background: #031525;
}
body.dark-mode .chat-type-dropdown-item:hover {

  background: #112334;
  
}
body.dark-mode .info-group label {
  color: var(--text-color);
}
/* Apply Dark Mode to Specific Components */
body.dark-mode .tab-content,
body.dark-mode .modal-card {
  background-color: #112334;
}

body.dark-mode .popup-content {
  background-color: #031525;
}
body.dark-mode .voice-selection-title {
 color: white;
}
body.dark-mode .toggle-button:hover {
  color: #007bff; /* Change color on hover */
}
/* Dark Mode Sidebar */
body.dark-mode .sidebar {
  background-color: #031525;
  color: var(--text-color);
}

body.dark-mode .sidebar nav ul li a {
  color: var(--text-color);
}

body.dark-mode .sidebar nav ul li.active a {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--text-color);
}

body.dark-mode .sidebar nav ul li a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

body.dark-mode .user-profile span {
  color: var(--text-color);
}

body.dark-mode .light-dark-toggle button {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--text-color);
}

body.dark-mode .light-dark-toggle button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

body.dark-mode .logo span {
  color: var(--text-color);
}

body.dark-mode .top-bar-title {
  color: var(--text-color);
}

/* Dark Mode Main Content Area */
body.dark-mode .project-manager,
body.dark-mode .project-card,
body.dark-mode .task-bar {
  background-color: #112334;
  color: var(--text-color);
}
body.dark-mode .filter-tabs button.active {
  background-color: rgba(255, 255, 255, 0.2);

}

body.dark-mode .main-content {
  background-color: #031525; /* Base background color */
  background-image: radial-gradient(#434343 1px, #3940478d 1px); /* Add border to separate content */
}

body.dark-mode .project-card {
  box-shadow: none; /* Remove shadows for a flat appearance */
}

body.dark-mode .project-card:hover {
  box-shadow: 0 6px 12px rgba(255, 255, 255, 0.1); /* Light shadow on hover */
}

body.dark-mode .project-input,
body.dark-mode .create-project-button {
  background-color: var(--main-bg-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

body.dark-mode .create-project-button:hover {
  background-color: #007bff;
}

body.dark-mode .add-project-card {
  background-color: #031525;
  border: 2px dashed var(--text-color);
}

body.dark-mode .confirmation-box {
  background-color: #3940478d;
  color: var(--text-color);
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
}

body.dark-mode .confirmation-box p {
  color: white;
}

/* Dark Mode Right Sidebar */
body.dark-mode .right-sidebar {
  background-color: #031525;
  color: var(--text-color);
}

body.dark-mode .right-sidebar h2,
body.dark-mode .right-sidebar ul li {
  color: var(--text-color);
}

body.dark-mode .right-sidebar ul li:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
body.dark-mode .right-sidebar ul li.active {
  background-color: rgba(255, 255, 255, 0.1);
}

body.dark-mode .popup-content {
  background: #031525; /* Match the background color of the Project Manager's add project card */
}
/* Dark Mode Task Bar */
body.dark-mode .search-input {
  background-color: var(--main-bg-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

body.dark-mode .layout-toggle-button {
  background-color: var(--button-bg-color);
  color: var(--text-color);
}

body.dark-mode .layout-toggle-button:hover {
  background-color: var(--button-hover-bg-color);
}

/* Dark Mode for Buttons */
body.dark-mode .prompt-button {
  background-color: #4a4b4d;
}

body.dark-mode .prompt-button:hover {
  background-color: #0a66e7;
}

body.dark-mode .upload-file-input {
  background-color: #424242;
}

body.dark-mode .upload-file-input:hover {
  background-color: #333333;
}

/* =============================================================
   Content Toolbar Styles
============================================================= */

/* Light Mode Content Toolbar */


.content-toolbar.minimized {
  width: 2%;
  padding: 20px 5px;
  justify-content: center;
  transition: width 0.1s ease, background-color 0.1s ease;
}

.toolbar-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.content-toolbar.minimized .toolbar-content {
  justify-content: center;
}

/* Toolbar Header */
.toolbar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title-icon {
  padding-top: 20px;
  cursor: default;
  font-size: 20px;
}

.toolbar-minimize-icon {
  cursor: pointer;
  margin-left: 10px;
  font-size: 15px;
  color: #007bff;
}

.toolbar-minimize-icon:hover {
  color: #0056b3;
  font-size: 20px;
}

/* Toolbar Icon */
.toolbar-icon {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  justify-content: center;
  color: #272179;
  position: relative;
}

.toolbar-icon span {
  margin-left: 10px;
}

.toolbar-icon:hover {
  color: #007bff;
}

/* Dark Mode Content Toolbar */
body.dark-mode .content-toolbar {
  background-color: #1a2835;
  border-left: 1px solid var(--border-color);
  color: var(--text-color);
}

body.dark-mode .content-toolbar.minimized {
  background-color: #1a2835;
}

body.dark-mode .toolbar-icon {
  color: var(--text-color);
}

body.dark-mode .toolbar-icon:hover {
  color: var(--button-hover-bg-color);
}

body.dark-mode .toolbar-minimize-icon {
  color: var(--text-color);
}

body.dark-mode .toolbar-minimize-icon:hover {
  color: var(--button-hover-bg-color);
}
.voice-controls-heading {

  color: var(--text-color); /* White text */
}
/* Dark Mode Tooltip */
body.dark-mode .tooltip-text {
  background-color: #555;
  color: #fff;
}

/* Dark Mode File List */
body.dark-mode .file-list li {
  border-bottom: 1px solid var(--border-color);
  color: var(--text-color);
}

body.dark-mode .file-list li a {
  color: var(--button-bg-color);
}

body.dark-mode .file-list li a:hover {
  color: var(--button-hover-bg-color);
}

/* =============================================================
   Miscellaneous Styles
============================================================= */

/* Settings Box */
body.dark-mode .settings-box {
  background-color: #555;
}

/* Hover Styles for Settings Items */
body.dark-mode .settings-item:hover {
  background-color: #333333;
}

/* Sidebar Icon Colors in Dark Mode */
body.dark-mode .sidebar nav ul li a .icon {
  color: #ffffff;
}
body.dark-mode .profile-container {
  background-color: #3940478d;
}

/* =============================================================
   TOP BAR STYLES
============================================================= */
body.dark-mode .top-bar-project-name {
  color: #007bff;

}