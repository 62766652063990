/* Ensure body can scroll only vertically */

  
  /* Main content area */

  /* Responsive video container */
  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
  
  /* Responsive iframe */
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    border: 0;
  }
  
  /* Tutorial section spacing */
  .tutorial-section {
    margin-bottom: 0px;
    padding: 0 0px;
    text-align: center;
  }
  /* Reset or control the margin and padding for headings */
.tutorial-section h2 {
    margin: 10px 0;
     /* Reduce the top and bottom margin */
    padding: 0; /* Remove any unwanted padding */
    font-size: 24px; /* Adjust font size as needed */
    text-align: center;
  }
  .quick-links-sidebar {
    width: 25%; /* Take up 25% of the width */
    position: sticky;
    top: 20px; /* Keep it fixed while scrolling */
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
  }
  
  .quick-links-sidebar h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .quick-links-sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .quick-links-sidebar li {
    margin-bottom: 10px;
  }
  
  .quick-links-sidebar a {
    text-decoration: none;
    color: #007bff;
  }
  
  .quick-links-sidebar a:hover {
    text-decoration: underline;
  }
  